import React, { useState } from 'react';
import Battle from 'models/battles/Battle';
import { wire } from 'react-hot-wire';
import UserProfileService from 'services/user/UserProfileService';
import classNames from 'classnames';
import images from 'assets/images';
import { ProgressBar } from 'components/_/common/progress-bar/ProgressBar';
import { getTimeDiff } from '../../helper/getTimeDiff';
import moment from 'moment/moment';
import { FormattedMessage } from 'react-intl';
import { useInterval } from 'usehooks-ts';

export type BattleDetailsModalContentProps = { battle: Battle };

export type BattleDetailsModalContentWiredProps = BattleDetailsModalContentProps & {
	'services.userProfileService': UserProfileService;
};
export const BattleDetailsModalContent = ({
	battle,
	'services.userProfileService': userProfileService,
}: BattleDetailsModalContentWiredProps) => {
	const currentUser = battle.players.find(player => player.userId === userProfileService.user()?.userProfile.id());
	const opponent = battle.players.find(player => player.userId !== userProfileService.user()?.userProfile.id());
	const isCurrentUserWinning = (currentUser?.score || 0) > (opponent?.score || 0);
	const opponentDominance = Math.round(
		((opponent?.score || 0) / ((currentUser?.score || 0) + (opponent?.score || 0))) * 100
	);
	const [, setKey] = useState(Math.random());

	useInterval(() => {
		setKey(Math.random);
	}, 1000);

	return (
		<div className="d-flex flex-column fs-4">
			<div className="mb-5">
				<p className="fs-5 font-weight-bold">
					<FormattedMessage
						id={
							currentUser?.score === opponent?.score
								? 'battle-list.details.title.draw'
								: isCurrentUserWinning
								? 'battle-list.details.title.winning'
								: 'battle-list.details.title.loosing'
						}
					/>
				</p>
				<p className="mt-1">
					<span className="battleDetails--color--gray">
						<FormattedMessage id={'battle-list.details.lastUpdate'} />
					</span>{' '}
					{battle.updatedAt.format('L')}
					{', '}
					{battle.updatedAt.format('LT')}
				</p>
			</div>

			<div className="d-flex flex-column w-100">
				<div className="font-weight-bold mb-1">
					<FormattedMessage id={'battle-list.details.you'} />
				</div>
				<div className="battleDetails__duel w-100 d-flex d-row">
					<div
						className={classNames(
							'battleDetails__duel__current d-flex d-row justify-content-between align-items-center',
							{
								'battleDetails__duel--winning': isCurrentUserWinning,
							}
						)}
						style={{ width: `${100 - opponentDominance}%` }}
					>
						{!!currentUser && (
							<>
								<div
									className="player__image rounded-circle background-image size--cover"
									style={{
										backgroundImage: `url(${currentUser.avatar || images.default.avatar.user})`,
									}}
									title={`${currentUser.firstname} ${currentUser.surname}`}
								/>
								<div>
									<div className="battleDetails__duel__score mr-3">
										{currentUser.originalScore === null ? '-' : currentUser.score}
									</div>
								</div>
							</>
						)}
					</div>
					<div
						className={classNames(
							'battleDetails__duel__opponent d-flex d-row justify-content-between align-items-center',
							{
								'battleDetails__duel--winning': !isCurrentUserWinning,
							}
						)}
						style={{ width: `${opponentDominance}%` }}
					>
						{!!opponent && (
							<>
								<div>
									<div className="battleDetails__duel__score ml-3">
										{opponent.originalScore === null ? '-' : opponent.score}
									</div>
								</div>
								<div
									className="player__image rounded-circle background-image size--cover"
									style={{
										backgroundImage: `url(${opponent.avatar || images.default.avatar.user})`,
									}}
									title={`${opponent.firstname} ${opponent.surname}`}
								/>
							</>
						)}
					</div>
				</div>
				{!!opponent && (
					<div className="font-weight-bold mt-1 w-100 text-end">{`${opponent.firstname} ${opponent.surname}`}</div>
				)}
			</div>

			<hr className="my-4" />

			<div>
				{battle.endDate.toDate() > new Date() ? (
					<>
						<p>
							<span className="battleDetails--color--gray">
								<FormattedMessage id={'battle-list.details.remainingTime'} />
							</span>{' '}
							{getTimeDiff(moment(), battle.endDate)}
						</p>
						<div className="mt-2">
							<ProgressBar
								progress={Math.round(
									((Date.now() - battle.startDate.toDate().getTime()) /
										(battle.endDate.toDate().getTime() - battle.startDate.toDate().getTime())) *
										100
								)}
								className="align-self-center flex-grow-1"
								border={0}
								innerPadding={0}
							/>
						</div>
					</>
				) : (
					<>
						<p>
							<FormattedMessage id={'battle-list.details.finished.title'} />
							<span className="battleDetails--color--gray">
								<FormattedMessage id={'battle-list.details.finished.subtitle'} />
							</span>
						</p>
						<div className="mt-2">
							<ProgressBar
								progress={100}
								className="align-self-center flex-grow-1"
								border={0}
								innerPadding={0}
							/>
						</div>
					</>
				)}
			</div>

			<hr className="my-4" />

			<div>
				<p className="fs-5 mb-3">{battle.name}</p>
				<p className="battleDetails--color--gray">{battle.description}</p>
			</div>
		</div>
	);
};

export default wire<BattleDetailsModalContentWiredProps, BattleDetailsModalContentProps>(
	['services.userProfileService'],
	BattleDetailsModalContent
);
